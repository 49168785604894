<qrcode
  #qrCode
  [qrdata]="data.url | async"
  [width]="qrCodeWidth"
  [errorCorrectionLevel]="'M'"
></qrcode>

<button
  mat-raised-button
  color="primary"
  (click)="saveQRImage()"
  class="qr-code__save"
>
  <mat-icon>save</mat-icon>
  <span i18n>SAVE QR-CODE</span>
</button>
