import { Observable } from "rxjs";
import { MathCalculator } from "./math-calculator";
import { of, forkJoin } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { CatalogProperty } from "app/shared/catalog.service";
import { EntityData, BuilderApplyInfo } from "./designer";
import { pb } from "./pb/scene";

// how Entity is shown in specification
export enum ElementBill {
  Default,
  Article,
  Disabled,
}

export interface ElementInfo {
  id?: string;
  rev?: number;
  sku?: string;
  bill?: ElementBill;
  price?: number;
  description?: string;
  offers?: number[];
  b3dId?: number;
  xbsType?: number;
  salonType?: number;
}

export enum ParameterType {
  Unknown,
  Material,
  Element,
  Width,
  Height,
  Depth,
  SKU,
  Price,
  Parameter,
  Position,
  Attribute,
  Property,
}

export class Parameter {
  constructor(public type: ParameterType, public value: string | number) {}

  toJson() {
    return {
      type: this.type,
      value: this.value,
    };
  }

  static fromJson(json) {
    return new Parameter(json.type, json.value);
  }
}

export class PropertyVariant {
  constructor(name: string) {
    this.name = name;
  }
  id = Math.round(Math.random() * 1000000);
  name: string;
  values: (string | number)[] = [];
  disabled = false;

  toJson() {
    let obj: any = {
      id: this.id,
      name: this.name,
      values: this.values,
    };
    if (this.disabled) {
      obj.disabled = true;
    }
    return obj;
  }

  static fromJson(json) {
    let result = new PropertyVariant(json.name);
    result.id = Number(json.id || result.id);
    result.values = json.values || [];
    result.disabled = !!json.disabled;
    if (json.params) {
      result.values = json.params.map((p) => p.value);
    }
    return result;
  }
}

// property switch one or set of parameters
export class Property {
  id: number;
  catalogId: number;
  name: string;
  description?: string;
  params: Parameter[] = [];
  variants: PropertyVariant[] = [];
  // variantId
  value = 0;
  linkedTo = 0;

  valueName(value?: number) {
    let variant = this.find(Math.abs(value || this.value));
    return variant && variant.name;
  }

  addParameter(
    type: ParameterType,
    value: string | number,
    variantValue?: string | number
  ) {
    let param = new Parameter(type, value);
    this.params.push(param);
    if (variantValue === undefined) {
      variantValue = type === ParameterType.Attribute ? 0 : value;
    }
    for (let variant of this.variants) {
      variant.values.push(variantValue);
    }
    return param;
  }

  deleteParameter(index: number) {
    this.params.splice(index, 1);
    for (let variant of this.variants) {
      variant.values.splice(index, 1);
    }
  }

  addVariant(name: string) {
    let variant = new PropertyVariant(name);
    if (this.variants.length > 0) {
      let last = this.variants[this.variants.length - 1];
      variant.values = [...last.values];
    }
    if (this.params.length === 1) {
      let param = this.params[0];
      let pt = ParameterType;
      if (
        param.type === pt.Width ||
        param.type === pt.Height ||
        param.type === pt.Depth ||
        param.type === pt.Parameter
      ) {
        let value = parseFloat(name);
        if (value > 0 && value < MathCalculator.MAX_RANGE) {
          variant.values[0] = value;
        }
      }
    }
    this.variants.push(variant);
    return variant;
  }

  find(variantId: number) {
    for (let variant of this.variants) {
      if (variant.id === variantId) {
        return variant;
      }
    }
  }

  findLinkedProperties() {
    let result: number[] = [];
    let active = this.variants.find((v) => v.id === this.value);
    if (active) {
      for (let i = 0; i < this.params.length; ++i) {
        let param = this.params[i];
        if (param.type === ParameterType.Property) {
          let propertyId = active.values[i];
          if (typeof propertyId === "number" && propertyId > 0) {
            result.push(propertyId);
          }
        }
      }
    }
    return result;
  }

  toJson() {
    return {
      params: this.params.map((p) => p.toJson()),
      variants: this.variants.map((v) => v.toJson()),
    };
  }

  toDataString() {
    return JSON.stringify(this.toJson());
  }

  loadFrom(json) {
    this.params = (json.params || []).map((data) => Parameter.fromJson(data));
    this.variants = (json.variants || []).map((data) =>
      PropertyVariant.fromJson(data)
    );
    // migrate old property format
    if (this.params.length === 0 && json.variants && json.variants[0]) {
      this.params = (json.variants[0].params || []).map((data) =>
        Parameter.fromJson(data)
      );
    }
    return this;
  }

  clone() {
    let copy = new Property();
    copy.id = this.id;
    copy.catalogId = this.catalogId;
    copy.name = this.name;
    copy.description = this.description;
    copy.params = [...this.params];
    copy.variants = [...this.variants];
    copy.value = this.value;
    copy.linkedTo = this.linkedTo;
    return copy;
  }

  static fromJson(json: string | any) {
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    return new Property().loadFrom(json);
  }

  static fromProperty(prop?: CatalogProperty) {
    if (!prop) {
      return undefined;
    }
    try {
      let newProp = prop.data
        ? Property.fromJson(JSON.parse(prop.data))
        : new Property();
      newProp.id = prop.id;
      newProp.catalogId = prop.catalogId;
      newProp.name = prop.name;
      return newProp;
    } catch {
      return undefined;
    }
  }
}

type ReplacementTable = { old: string; new: string }[];

export interface EntityPropertyInfo {
  // store negative property values to indicate linked properties
  props?: [number, number][];
  materials: ReplacementTable;
  size?: any;
  sku?: string;
  price?: number;
  position?: pb.Elastic.Position;
  revision: number;
}

export class ModelProperties {
  // maps catalog properties to its values
  props: Property[] = [];
  // maps
  materials: ReplacementTable = [];
  size: { [size: string]: number } = {};
  attributes: { [key: string]: number | string } = {};
  sku?: string;
  price = 0;
  position?: pb.Elastic.Position;
  revision = 0;

  hasProperty(id: number) {
    return this.props.some((p) => p.id === id);
  }

  hasLinkedProperties() {
    return this.props.some(
      (p) => p.linkedTo || p.findLinkedProperties().length
    );
  }

  addProperty(prop: Property) {
    let oldProp = this.props.find((p) => p.id === prop.id);
    if (oldProp) {
      oldProp.variants = prop.variants;
      if (!oldProp.variants.some((v) => v.id === oldProp.value)) {
        oldProp.value = oldProp.variants[0].id;
      }
    } else {
      prop.value = prop.variants[0].id;
      this.props.push(prop);
    }
  }

  private updateParams() {
    this.revision++;
    this.materials = [];
    let size: { [size: string]: number } = {};
    this.attributes = {};
    this.price = 0;
    this.sku = undefined;
    this.position = undefined;
    let resize = false;
    for (let property of this.props) {
      if (property && property.variants.length > 1) {
        let variant = property.find(property.value);
        if (!variant) {
          continue;
        }
        for (let i = 0; i < property.params.length; i++) {
          let param = property.params[i];
          let value = variant.values[i];
          if (param.type === ParameterType.Material) {
            let pair = {
              old: property.params[i].value as string,
              new: value as string,
            };
            if (pair.old !== pair.new) {
              this.materials.push(pair);
            }
          } else if (param.type === ParameterType.Width) {
            size["#width"] = Number(value);
            resize = true;
          } else if (param.type === ParameterType.Height) {
            size["#height"] = Number(value);
            resize = true;
          } else if (param.type === ParameterType.Depth) {
            size["#depth"] = Number(value);
            resize = true;
          } else if (param.type === ParameterType.SKU) {
            this.sku = (this.sku || "") + (value || "").toString();
          } else if (param.type === ParameterType.Price) {
            this.price += Number(value);
          } else if (param.type === ParameterType.Position) {
            this.position = Number(value);
          } else if (param.type === ParameterType.Parameter) {
            size[param.value] = Number(value);
            resize = true;
          } else if (param.type === ParameterType.Attribute) {
            this.attributes[param.value] = value;
          }
        }
      }
    }
    if (resize) {
      this.size = size;
    }
  }

  loadProperties(
    props: [number, number][],
    loader: (id: number) => Observable<Property>
  ) {
    let topProps = props.filter((p) => p[1] >= 0);
    if (topProps.length === 0) {
      this.props = [];
      return of(this);
    }
    const queue = of(topProps).pipe(
      map((props) => props.filter((p) => p[1] >= 0).map((p) => p[0]))
    );
    const propLoader = (propId: number, linkedTo = 0) => {
      return loader(propId).pipe(
        map((prop) => {
          if (prop) {
            // we shouldn't modify property returned from loader
            prop = prop.clone();
            prop.variants = prop.variants.filter((v) => !v.disabled);
            prop.value = Math.abs(
              props.find((p) => p[0] === prop.id)?.[1] || 0
            );
            prop.linkedTo = linkedTo;
            if (!prop.value && prop.variants.length > 0) {
              prop.value = prop.variants[0].id;
            }
          }
          return prop;
        })
      );
    };
    const result = queue.pipe(
      concatMap((q) => forkJoin(q.map((id) => propLoader(id))))
    );
    return result.pipe(
      concatMap((propList) => {
        propList = propList.filter((v) => !!v);
        let linkedProps: { id: number; parent: number }[] = [];
        for (let prop of propList) {
          linkedProps.push(
            ...prop
              .findLinkedProperties()
              .map((id) => ({ id, parent: prop.id }))
          );
        }
        if (linkedProps.length > 0) {
          return of(linkedProps).pipe(
            concatMap((ids) =>
              forkJoin(ids.map((l) => propLoader(l.id, l.parent)))
            ),
            map((newProps) => {
              let combinedProps = [...propList];
              for (let prop of newProps) {
                let index = combinedProps.findIndex(
                  (p) => p.id === prop.linkedTo
                );
                combinedProps.splice(index + 1, 0, prop);
              }
              return combinedProps;
            })
          );
        }
        return of(propList);
      }),
      map((propList) => {
        this.props = propList;
        return this;
      })
    );
  }

  load(
    data: { propInfo?: EntityPropertyInfo },
    loader: (id: number) => Observable<Property>
  ) {
    if (data && data.propInfo) {
      let info = data.propInfo;
      this.materials = info.materials || [];
      this.revision = info.revision || 0;
      this.size = info.size || {};
      this.sku = info.sku;
      this.price = info.price;
      this.position = info.position;
      return this.loadProperties(info.props || [], loader);
    } else {
      return of(this);
    }
  }

  getPropertyMap(linked = true) {
    let props = this.props;
    if (!linked) {
      props = props.filter((p) => !p.linkedTo);
    }
    return props.map(
      (p) => [p.id, p.linkedTo ? -p.value : p.value] as [number, number]
    );
  }

  save() {
    this.updateParams();
    let result: BuilderApplyInfo = { data: { propInfo: null, merge: true } };
    if (this.props.length > 0) {
      let propInfo: EntityPropertyInfo = {
        props: this.getPropertyMap(),
        materials: this.materials,
        size: this.size,
        sku: this.sku,
        price: this.price,
        revision: this.revision,
      };
      result.data.propInfo = propInfo;
      if (this.sku) {
        result.data.model = { sku: this.sku };
      }
      if (this.size) {
        result.size = this.size;
      }
      if (Object.keys(this.attributes).length > 0) {
        result.data.attributes = this.attributes;
      }
      if (this.position || this.position === 0) {
        result.elastic = { position: this.position };
      }
    }
    return result;
  }

  static containsProperty(data: EntityData, propId: number) {
    if (data.propInfo && data.propInfo.props) {
      let propInfo = data.propInfo as EntityPropertyInfo;
      return propInfo.props.some((p) => p[0] === propId);
    }
    return false;
  }

  public clear() {
    this.props = [];
    this.materials = [];
  }
}
