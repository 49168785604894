import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QRCodeComponent } from "angularx-qrcode";
import { dataURItoFile } from "app/shared";
import { saveAsDialog } from "app/shared/filesaver";
import { Observable } from "rxjs";

export interface ProjectLinkQRCodeData {
  url: Observable<string>;
  name: string;
}

@Component({
  selector: "app-project-link-qr-code",
  templateUrl: "./project-link-qr-code.component.html",
  styleUrls: ["./project-link-qr-code.component.scss"],
})
export class ProjectLinkQRCodeComponent {
  qrCodeWidth = 512;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectLinkQRCodeData,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(["(max-width: 700px)", "(max-height: 600px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.qrCodeWidth = 256;
        }
      });
  }

  @ViewChild("qrCode", { static: true }) qrCode: QRCodeComponent;
  saveQRImage() {
    try {
      const base64 = this.qrCode.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/jpeg");
      saveAsDialog(dataURItoFile(base64), `${this.data.name || ""}_QRCode.jpg`);
    } catch (error) {
      console.log(error);
    }
  }
}
