import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { AuthService } from "app/shared";
import { DialogService } from "app/dialogs/services/dialog.service";
import { ProjectLinkQRCodeComponent } from "./project-link-qr-code/project-link-qr-code.component";

export interface ProjectLinkComponentData {
  id: number;
  name: string;
  url: Observable<string>;
  editableUrl?: Observable<string>;
  scriptInterface: any;
}

@Component({
  selector: "app-project-link",
  templateUrl: "./project-link.component.html",
  styleUrls: ["./project-link.component.scss"],
})
export class ProjectLinkComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectLinkComponentData,
    public auth: AuthService,
    private dialog: DialogService
  ) {}

  editable = new UntypedFormControl(false);

  @ViewChild("linkElem") linkRef: ElementRef;
  copied = false;

  ngOnInit() {}

  get url() {
    return this.editable.value ? this.data.editableUrl : this.data.url;
  }

  copy() {
    (this.linkRef.nativeElement as HTMLTextAreaElement).select();
    document.execCommand("copy");
    this.copied = true;
  }

  showQRCode() {
    this.dialog.open(ProjectLinkQRCodeComponent, {
      data: { url: this.url, name: this.data.name },
      panelClass: "project-link__qr-code",
    });
  }
}
